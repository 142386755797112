import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auth: false,
  data: {}
}

export const userSlice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    setUserAuth: (state, action) => {
      state.auth = action.payload
    },

    setUserData: (state, action) => {
      const current_points = state.data.points
      if (current_points && action.payload.points && current_points > action.payload.points) {
        // Never decrease points just in case
        action.payload.points = current_points
      }
      state.data = action.payload
    },

    logoutReload: (state) => {
      state.auth = initialState.auth
      state.data = initialState.data
      setTimeout(() => location?.reload?.(), 100)
    },

    logout: (state) => {
      state.auth = initialState.auth
      state.data = initialState.data
    }
  }
})

export const { setUserAuth, setUserData, logoutReload, logout } = userSlice.actions

export const selectUserAuth = (state) => state.user.auth
export const selectUserData = (state) => state.user.data

export default userSlice.reducer
