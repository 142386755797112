import jsonFetch from 'json-fetch'

import { setUserData } from '../features/user/userSlice'

export async function postFeedback (body) {
  return (await jsonFetch('/api/feedback', {
    method: 'POST',
    expectedStatuses: [200, 201],
    body
  }))?.body
}

export async function postOAuthToken (body) {
  return (await jsonFetch('/api/oauth/token', {
    method: 'POST',
    expectedStatuses: [200, 201, 400, 401],
    body
  }))?.body
}

export async function fetchUser (auth) {
  return (await jsonFetch('/api/user', {
    method: 'GET',
    expectedStatuses: [200, 201, 404],
    headers: { authorization: auth.access_token }
  }))?.body
}

export async function patchUser (auth, body) {
  return (await jsonFetch('/api/user', {
    method: 'PATCH',
    expectedStatuses: [200, 201, 400],
    headers: { authorization: auth.access_token },
    body
  }))?.body
}

export async function updateUserData (auth, dispatch) {
  const user = await fetchUser(auth)

  await dispatch(setUserData(user))

  return user
}

export async function fetchUserAchievements (auth) {
  return (await jsonFetch('/api/user_achievements', {
    method: 'GET',
    expectedStatuses: [200, 201, 404],
    headers: { authorization: auth.access_token }
  }))?.body?.achievements ?? []
}

export async function patchUserAchievement (auth, body) {
  return (await jsonFetch('/api/user_achievements', {
    method: 'PATCH',
    expectedStatuses: [200, 201, 400],
    headers: { authorization: auth.access_token },
    body
  }))?.body
}

export async function patchUserGame (auth, body) {
  return (await jsonFetch('/api/user_games', {
    method: 'PATCH',
    expectedStatuses: [200, 201, 400],
    headers: { authorization: auth.access_token },
    body
  }))?.body
}

export async function fetchUserLogs (auth) {
  return (await jsonFetch('/api/user_logs', {
    method: 'GET',
    expectedStatuses: [200, 201, 404],
    headers: { authorization: auth.access_token }
  }))?.body?.logs ?? []
}

export async function putUserLog (auth, body) {
  return (await jsonFetch('/api/user_logs', {
    method: 'PUT',
    expectedStatuses: [200, 201, 400],
    headers: { authorization: auth.access_token },
    body
  }))?.body
}
