import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import Image from 'next/image'

import { logoutReload, selectUserAuth } from '../../app/features/user/userSlice'

import about from './about.png'
import fullscreen from './fullscreen.png'
import logout from './logout.png'
import volume0 from './volume-0.png'
import volume1 from './volume-1.png'
import volume2 from './volume-2.png'
import volume3 from './volume-3.png'

const VOLUME_ICONS = [
  volume0, volume1, volume2, volume3
]

const ICON_SIZE = 24

export default function CornerButtons ({ fullScreenHandle }) {
  const dispatch = useDispatch()
  const auth = useSelector(selectUserAuth)
  const [volume, setVolume] = useState(3)

  useEffect(() => {
    Howler.volume(volume / 3)
  }, [volume])

  return (
    <>
      <div className='corner-buttons'>
        {auth && <a onClick={() => dispatch(logoutReload())} title='Entrar com outra conta'>
          <Image src={logout} alt='Logout' width={ICON_SIZE} height={ICON_SIZE} />
        </a>}
        <Link href='/about'>
          <a title='Acerca de este site'>
            <Image src={about} alt='About' width={ICON_SIZE} height={ICON_SIZE} />
          </a>
        </Link>
        <a onClick={() => setVolume((volume + 1) % 4)} title='Volumen'>
          <Image src={VOLUME_ICONS[volume]} alt='Sound' width={ICON_SIZE} height={ICON_SIZE} />
        </a>
        <a onClick={fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter} title='Tela cheia'>
          <Image src={fullscreen} alt='Fullscreen' width={ICON_SIZE} height={ICON_SIZE} />
        </a>
      </div>
    </>
  )
}
